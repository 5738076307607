import React, { useEffect, useRef } from "react"

import "./intro.styles.scss"


import Header from "../header/header.component"

const Intro = () => {

const parallaxed = useRef(null);

useEffect(() => {
  const handleScroll = () => {
    const el = parallaxed.current;

    let offset = window.pageYOffset;
    window.requestAnimationFrame(() => {
      el.style.marginTop = `${offset/1.5}px`;
		  el.style.opacity = 1 - (offset/250);
    })
  
  }
  window.addEventListener('scroll', handleScroll)
  return () => window.removeEventListener('scroll', handleScroll);
}, [])
return (

  <div className="intro-container">
  <div className="gradient-fade-up"></div>
    <Header className="navbar" siteTitle={`One Guy Detail`} />
      <div className="logo-container" ref={parallaxed} id="logo-container">
        <div className="svg-logo-container">
          <svg
            width="215.217391304"
            height="150"
            viewBox="0 0 132 92"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M69 5C62.2168 1.49456 54.6478 0 45.5717 0C36.7822 0 28.9003 1.91576 21.926 5.74729C15.0472 9.57881 9.64934 14.9592 5.73228 21.8886C1.91076 28.8179 0 36.7663 0 45.7337C0 54.9457 1.86299 62.894 5.58898 69.5788C9.4105 76.1821 14.7129 81.2364 21.4961 84.7418C28.3748 88.2473 36.3522 90 45.4283 90C54.2179 90 62.052 88.0842 68.9307 84.2527C75.905 80.4212 81.3029 75.0408 85.1244 68.1114C89.0415 61.1821 91 53.2337 91 44.2663C91 35.0543 89.0892 27.1467 85.2677 20.5435C82.6571 15.8599 79.2727 11.9566 75.1145 8.83369C71.9447 11.0255 70.7701 12.0278 68.4208 15.0347C69.8649 16.8582 71.3751 20.0648 72.3701 22.3777C75.2362 28.8179 76.6693 36.2772 76.6693 44.7554C76.6693 53.1522 75.3795 60.6114 72.8 67.1331C70.316 73.6549 66.7333 78.75 62.052 82.4185C57.4661 86.0054 52.116 87.7989 46.0016 87.7989C40.0782 87.7989 34.7281 86.0462 29.9512 82.5408C25.1743 79.0353 21.3528 74.0625 18.4866 67.6223C15.716 61.1821 14.3307 53.7228 14.3307 45.2446C14.3307 36.8478 15.5727 29.3886 18.0567 22.8668C20.6362 16.3451 24.2189 11.2908 28.8047 7.70381C33.4861 4.03533 38.884 2.20109 44.9984 2.20109C50.9218 2.20109 56.2719 3.95381 61.0488 7.45924C61.4003 7.71716 62.5 8.5 62.5 8.5C64.3689 7.0259 66 6 69 5Z"
              fill="#ffffff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M70.7122 83.9906C69.8718 84.523 69.0064 85.0315 68.116 85.5159C67.1478 86.0499 66.1614 86.5476 65.1571 87.009C72.2342 90.3363 80.6707 92 90.4667 92C94.9046 92 98.9084 91.4293 102.478 90.288C106.144 89.2283 109.666 87.5978 113.042 85.3967C114.489 84.5 115.502 83.9293 116.081 83.6848C116.66 83.4402 117.239 83.3179 117.818 83.3179C118.976 83.3179 119.892 84.0924 120.567 85.6413C121.243 87.1087 121.629 88.6576 121.725 90.288H124.62V66.0761C124.62 62.9783 124.764 60.7364 125.054 59.3505C125.44 57.9647 126.115 57.0272 127.08 56.538C128.141 56.0489 129.781 55.7228 132 55.5598V53.1141C128.527 53.3587 123.51 53.481 116.95 53.481C108.363 53.481 101.562 53.3587 96.5447 53.1141V55.5598C100.886 55.7228 104.022 56.0897 105.951 56.6603C107.881 57.231 109.231 58.2908 110.003 59.8397C110.775 61.3886 111.161 63.875 111.161 67.2989V79.038C111.161 80.6685 110.872 81.9728 110.293 82.9511C109.81 83.8478 108.701 84.7853 106.964 85.7636C102.719 88.2908 97.7024 89.5543 91.9138 89.5543C83.1278 89.5543 76.0606 87.6998 70.7122 83.9906Z"
              fill="#ffffff"
            />
            <path
              d="M89.5984 2C95.2905 2 99.9696 2.69294 103.636 4.07881C107.302 5.38316 111.306 7.33968 115.647 9.94838C116.901 10.7636 117.866 11.1712 118.541 11.1712C120.375 11.1712 121.58 8.68479 122.159 3.71196H125.488C125.102 9.01088 124.909 18.3859 124.909 31.837H121.58C120.712 27.5163 119.796 23.9701 118.831 21.1984C117.866 18.3451 116.371 15.8587 114.345 13.7391C111.547 10.8043 107.881 8.48098 103.346 6.76903C98.9084 5.05707 94.2293 4.20109 89.3089 4.20109C82.9415 4.20109 77.3458 5.95381 72.5219 9.45924C67.6981 12.9647 63.9837 17.9783 61.3789 24.5C58.774 30.9402 57.4715 38.481 57.4715 47.1223C57.4715 60.5466 59.1621 72.6485 64.5 78.5C64.5 78.5 62 81.5 59 83C54.9591 79.9702 51.1867 76.2308 48.6439 71.8234C44.8813 65.3016 43 57.5163 43 48.4674C43 39.1739 44.9778 31.0217 48.9333 24.0109C52.9854 16.9185 58.5328 11.4973 65.5756 7.74729C72.6184 3.91576 80.626 2 89.5984 2Z"
              fill="#ffffff"
            />
          </svg>
        </div>
        <div className="statement">
          <h1>One Guys Auto Detail</h1>
        </div>
      </div>
    </div>
  )
}

export default Intro
